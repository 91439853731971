import { useEffect } from "react";
import Marquee from "react-fast-marquee";
import SchoolIcon from "@mui/icons-material/School";
import ComputerIcon from "@mui/icons-material/Computer";
import { Container, useTheme, Grid } from "@mui/material";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";

import PCGif from "../assets/images/pc.gif";
import DateObject from "../utils/DateObject";
import PePatchaImg from "../assets/images/PePatcha.webp";

const HomePage = () => {
    const theme = useTheme();
    
    const scaleHeader = () => {
        var scalable = document.querySelectorAll(".scale--js");
        var margin = -4;
        for (var i = 0; i < scalable.length; i++) {
            var scalableContainer = scalable[i].parentNode;
            scalable[i].style.transform = "scale(1)";
            const scalableContainerWidth = scalableContainer.offsetWidth - margin;
            const scalableWidth = scalable[i].offsetWidth;
            scalable[i].style.transform = "scale(" + scalableContainerWidth / scalableWidth + ")";            
            scalableContainer.style.height = scalable[i].getBoundingClientRect().height + 3 + "px";
        }
    }

    useEffect(()=>{
        scaleHeader();
        window.addEventListener("resize", scaleHeader);
    }, []);

    return (
        <div className="flex justify-center pb-1">
            <Container maxWidth="lg">
                <div className="w-full h-1 sm:h-1.5 mb-1" style={{ backgroundColor: theme.palette.text.primary }} />
                <div className="scale__container--js hidden sm:block">
                    <h1 className="scale--js main-scale--js font-black cursor-default">PATCHARA SUWANBORDIN</h1>
                </div>
                <div className="scale__container--js block sm:hidden">
                    <span className="scale--js main-scale--js font-black cursor-default">PATCHARA</span>
                </div>
                <div className="scale__container--js block sm:hidden">
                    <span className="scale--js main-scale--js font-black cursor-default">SUWANBORDIN</span>
                </div>
                <div className="w-full h-1 sm:h-1.5 mb-4" style={{ backgroundColor: theme.palette.text.primary }} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} className="flex">
                        <div>
                            <div className="scale__container--js bg-red-500">
                                <span className="scale--js font-black cursor-default px-2 py-1">Full Stack Developer</span>
                            </div>
                            <p className="pt-3 sm:text-lg md:text-xl txt-full-justify">
                                Hi, I am a freelance web developer based in Bangkok, and a passionate full-stack developer with over {DateObject.getYearCount("2019-02-01")} years of hands-on experience, specializing in building web and mobile applications for tech service companies. Skillful in providing solutions to solving problems with code. I would jump at any opportunity to continue improving my skillset.
                            </p>
                        </div>
                        <div className="logo desaturate"></div>
                    </Grid>
                    <Grid item xs={12} sm={5} className="flex">
                        <div className="w-full bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${PePatchaImg})`, minHeight: 220 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Marquee gradient={false} className="bg-red-500 text-lg font-bold py-1">
                            JAVASCRIPT • TYPESCRIPT • PHP • PYTHON • REACT • REACT-NATIVE • NODEJS • NESTJS • LARAVEL • HTML • CSS • SQL • GIT •&nbsp;
                        </Marquee>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={9} className="flex flex-col">
                        <p className="bigFirst txt-full-justify">
                            My personal experience has been working for many tech companies with many types of clients in the past {DateObject.getYearCount("2017-01-01")} years including my freelancer period, and I have built lots of different types of platforms like E-commerce, E-learning, insurance, backoffice, CMS platform, and many more.
                        </p>
                        <div className="w-full h-full mt-3" style={{ backgroundColor: "#eee" }}>
                            <div className="w-full h-full bg-no-repeat bg-center bg-cover grey-filter" style={{ backgroundImage: `url(${PCGif})` }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="flex flex-col">
                        <div className="w-full h-px" style={{ backgroundColor: theme.palette.text.primary }} />
                        <div className="text-center">
                            <span>Timeline</span>
                        </div>
                        <div className="w-full h-px" style={{ backgroundColor: theme.palette.text.primary }} />
                        <ol className="relative border-l border-gray-400 dark:border-gray-700 ml-3 mt-4">
                            {[
                                { year: 2019, icon: ComputerIcon, text: "Start working a full-time job as a full-stack developer in tech service companies" },
                                { year: 2018, icon: SchoolIcon, text: "Graduate bachelor's degree of science with information technology as a major from Suan Sunandha Rajabhat University" },
                                { year: 2017, icon: ComputerIcon, text: "Decided to start getting jobs as a freelance" },
                                { year: 2015, icon: SchoolIcon, text: "Graduate high school from Joseph Upatham School with Mathematics-Arts program" },
                                { year: 1997, icon: AccessibilityNewIcon, text: "Born on 6th Feb in Samut Sakhon, Thailand" }
                            ].map(item => {
                                const GetIcon = item["icon"];
                                return(
                                    <li key={`pe-time-line-${item["year"]}`} className="mb-4 ml-6">
                                        <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full dark:bg-blue-900">
                                            <GetIcon className="w-4 h-4 text-blue-600 dark:text-blue-400" />
                                        </span>
                                        <h3 className="mb-1 text-lg font-semibold text-gray-900 dark:text-white">{item["year"]}</h3>
                                        <p className="text-sm text-gray-500 dark:text-gray-400 txt-full-justify">{item["text"]}</p>
                                    </li>
                                )
                            })}
                        </ol>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default HomePage;