import { useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import AmbienceSound from "../components/AmbienceSound";

const HomeLayout = ({children, setThemeMode, isDark}) => {
    const [ambienceVol, setAmbienceVol] = useState(0);

    return (
        <main className="min-h-screen">
            <NavBar isDark={isDark} onModeChange={setThemeMode} ambienceVol={ambienceVol} setAmbienceVol={setAmbienceVol} />
            <AmbienceSound vol={ambienceVol} setAmbienceVol={setAmbienceVol} />
            {children}
            <Footer />
        </main>
    );
}

export default HomeLayout;