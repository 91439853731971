class DateObject {

    static today;
    static day;
    static month;
    static year;
    static hour;
    static minute;
    static second;

    static WD_LIST_TH = ["จ.", "อ.", "พ.", "พฤ.", "ศ."];
    static WEEKDAY_LIST_TH = ["วันจันทร์", "วันอังคาร", "วันพุธ", "พฤหัสบดี", "วันศุกร์"];
    static EVERY_WD_LIST_TH = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];
    static EVERY_WEEKDAY_LIST_TH = ["วันอาทิตย์", "วันจันทร์", "วันอังคาร", "วันพุธ", "พฤหัสบดี", "วันศุกร์", "วันเสาร์"];
    static MON_LIST_TH = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
    static MONTH_LIST_TH = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    
    static WD_LIST = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    static WEEKDAY_LIST = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    static EVERY_WD_LIST = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    static EVERY_WEEKDAY_LIST = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    static MON_LIST = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    static MONTH_LIST = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    static sortDateTime(valueFrist, valueSecond) {
        const [firstTime, firstDate] = valueFrist.split("/").map(e => e.trim());
        const [fHour, fMin] = firstTime.split(":").map(e => e.trim());
        const [fDate, fMonth, fYear] = firstDate.split(" ").map(e => e.trim());
        const fMonthToIndex = this.MON_LIST_TH.indexOf(fMonth)
        const [secondTime, secondDate] = valueSecond.split("/").map(e => e.trim());
        const [sHour, sMin] = secondTime.split(":").map(e => e.trim());
        const [sDate, sMonth, sYear] = secondDate.split(" ").map(e => e.trim());
        const sMonthToIndex = this.MON_LIST_TH.indexOf(sMonth)

        const resultCompareYear = this.compareDateTime(fYear, sYear)
        const resultCompareMonth = this.compareDateTime(fMonthToIndex, sMonthToIndex)
        const resultCompareDate = this.compareDateTime(fDate, sDate)
        const resultCompareHour = this.compareDateTime(fHour, sHour)
        const resultCompareMin = this.compareDateTime(fMin, sMin)

        if(resultCompareYear !== 0){ return resultCompareYear }
        if(resultCompareMonth !== 0){ return resultCompareMonth }
        if(resultCompareDate !== 0){ return resultCompareDate }
        if(resultCompareHour !== 0){ return resultCompareHour }
        if(resultCompareMin !== 0){ return resultCompareMin }
    }

    static compareDateTime(fristDT, secondDT) {
        if(fristDT < secondDT) {
            return -1
        } else if(fristDT > secondDT) {
            return 1
        } else {
            return 0
        }
    }

    static changeStringToArray(data, target) {
        const dataTarget = data?.filter(ele => ele.inputName === target)[0]?.inputValue
        return dataTarget?.split("|").map(ele => ({ label: ele.trim(), value: ele.trim() })) || []
    }

    static getCurrentTime() {
        this.today = new Date();
        this.day = String(this.today.getDate()).padStart(2, "0");
        this.month = String(this.today.getMonth() + 1).padStart(2, "0");
        this.year = this.today.getFullYear();
        this.hour = String(this.today.getHours()).padStart(2, "0");
        this.minute = String(this.today.getMinutes()).padStart(2, "0");
        this.second = String(this.today.getSeconds()).padStart(2, "0");
    }

    static genarateYearOptionList = () => {
        this.getCurrentTime()
        const yearOptionList = []
        for(let i = 2000; i <= this.year; i++) {
            yearOptionList.push({ label: i, value: i })
        }
        return yearOptionList
    }

    static getDateTime(dateFormat = "YYYY-MM-DD HH:mm:ss") {
        this.getCurrentTime()
        return dateFormat.replace(/YYYY/g, this.year).replace(/MM/g, this.month).replace(/DD/g, this.day)
            .replace(/HH/g, this.hour)
            .replace(/mm/g, this.minute)
            .replace(/ss/g, this.second)
    }

    static convertDateTime(dateString, dateFormat = "YYYY-MM-DD HH:mm:ss", isBC = false) {
        let date = new Date(dateString);
        let rawMonth = date.getMonth();
        let rawDay = date.getDay();
        let day = String(date.getDate()).padStart(2, "0");
        let month = String(rawMonth + 1).padStart(2, "0");
        let year = date.getFullYear();
        let hour = date.getHours();
        let minute = String(date.getMinutes()).padStart(2, "0");
        let second = String(date.getSeconds()).padStart(2, "0");

        let AP = "AM";
        const isHours12 = dateFormat.includes("AP");
        if(isHours12){
            if(hour > 11) AP = "PM";
            if(hour > 12) hour = hour - 12;
            if(hour === 0) hour = 12;
        }

        return dateFormat.replace(/YYYY/g, year)
            .replace(/yyyy/g, isBC ? year : year + 543)
            .replace(/MM/g, month)
            .replace(/DD/g, day)
            .replace(/HH/g, String(hour)).padStart(2, "0")
            .replace(/mm/g, minute)
            .replace(/ss/g, second)
            .replace(/AP/g, AP)
            .replace(/Month/g, isBC ? DateObject.MONTH_LIST[rawMonth] : DateObject.MONTH_LIST_TH[rawMonth])
            .replace(/Mon/g, isBC ? DateObject.MON_LIST[rawMonth] : DateObject.MON_LIST_TH[rawMonth])
            .replace(/dd/g, isBC ? DateObject.EVERY_WEEKDAY_LIST[rawDay] : DateObject.EVERY_WEEKDAY_LIST_TH[rawDay])
            .replace(/d/g, isBC ? DateObject.EVERY_WD_LIST[rawDay] : DateObject.EVERY_WD_LIST_TH[rawDay])
    }

    static compareDate(firstDate, secondDate, compareType) {
        let firstTimestamp = new Date(firstDate);
        let secondTimestamp = new Date(secondDate);
        let returnBoolean = false;
        switch (compareType) {
            case 'LESS_THAN_EQUAL':
                returnBoolean = (firstTimestamp <= secondTimestamp);
                break;
            case 'LESS_THAN':
                returnBoolean = (firstTimestamp < secondTimestamp);
                break;
            case 'MORE_THAN_EQUAL':
                returnBoolean = (firstTimestamp >= secondTimestamp);
                break;
            case 'MORE_THAN':
                returnBoolean = (firstTimestamp > secondTimestamp);
                break;
            default:
                break;
        }
        return returnBoolean;
    }

    static getBEDateTime(){
        this.getCurrentTime();
        return new Date(new Date().setFullYear(this.year+543))
    }

    static getYearCount(dateString){
        const getDate = new Date(dateString);
        const years = new Date(new Date() - new Date(getDate)).getFullYear() - 1970;

        return years;
    }

}

export default DateObject;