import React from "react";
import { isMobile } from "react-device-detect";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Zoom, AppBar, Toolbar, Container, IconButton, Slider } from "@mui/material";

const NavBar = ({isDark, onModeChange, ambienceVol, setAmbienceVol}) => {
    return (
        <AppBar position="static" color="transparent" sx={{boxShadow: "unset"}}>
            <Container maxWidth="lg">
                <Toolbar disableGutters variant="dense" className="flex justify-end">
                    <div className="flex-grow">
                        <a className="cursor-pointer mr-1.5" aria-label="Read more about me on linkin" href="https://www.linkedin.com/in/pepatcha/" target={"_blank"} rel="noreferrer"><LinkedInIcon id="linkin-icon" className="hover:text-black/70 dark:hover:text-white/75" /></a>
                        <a className="cursor-pointer" aria-label="Read more about me on instagram" href="https://www.instagram.com/pepatcha_/" target={"_blank"} rel="noreferrer"><InstagramIcon id="ig-icon" className="hover:text-black/70 dark:hover:text-white/75" /></a>
                    </div>
                    <div className="z-999 flex w-max turn-sound items-center mr-2">
                        {!isMobile ?
                            <Zoom in={true}>
                                <Slider className="cursor-pointer w-16 mr-2 am-slider" size="small" value={ambienceVol} min={0} max={100} onChange={e=>setAmbienceVol(e.target.value)} color="text" sx={{ "& .MuiSlider-thumb": { "&:focus, &:hover, &.Mui-active": { boxShadow: "unset" } } }} />
                            </Zoom>
                        : null }
                        {!ambienceVol ? <VolumeOffIcon className="cursor-pointer" onClick={() => setAmbienceVol(ambienceVol || 20)} /> : <VolumeUpIcon className="cursor-pointer" onClick={() => setAmbienceVol(0)} />}
                    </div>
                    <Zoom in={!isDark ? true : false} className="flex dark:hidden z-999">
                        <IconButton aria-label="light-mode" onClick={()=>onModeChange("dark")} color="text" >
                            <DarkModeIcon />
                        </IconButton>
                    </Zoom>
                    <Zoom in={isDark ? true : false} className="hidden dark:flex z-999">
                        <IconButton aria-label="dark-mode"  onClick={()=>onModeChange("light")} color="text" >
                            <LightModeIcon />
                        </IconButton>
                    </Zoom>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavBar;