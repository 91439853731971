import React, { useState, Suspense, useMemo, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import THEME from "./configs/theme";
import AllRoutes from "./configs/AllRoutes";
import PALETTE_DARK from "./configs/theme/paletteDark";
import PALETTE_LIGHT from "./configs/theme/paletteLight";

function App() {
    const [themeMode, setThemeMode] = useState(localStorage?.theme ? localStorage["theme"] : window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
    const genTheme = useMemo(() => createTheme({...THEME, palette: (themeMode === "dark") ? PALETTE_DARK : PALETTE_LIGHT}), [themeMode]);

    useEffect(()=>{
        checkClassMode(themeMode);
    }, [])
    
    const onModeChange = (mode) => {
        setThemeMode(mode);
        checkClassMode(mode);
        localStorage.setItem("theme", mode);
    }

    const updateTheme = (mode) => {
        const getModeBGColor = (mode === "dark") ? "#182030" : "#F5F2E8";
        document.getElementsByTagName("html")[0].style.background = getModeBGColor;
        document?.querySelector("meta[name=theme-color]").setAttribute("content", getModeBGColor)
    }

    const checkClassMode = (mode) => {
        if(mode === "dark"){
            document?.documentElement.classList.add("dark");
        }else{
            document?.documentElement.classList.remove("dark");
        }
        updateTheme(mode)
        document?.documentElement?.setAttribute("data-theme", mode);
    }

    return (
        <ThemeProvider theme={genTheme}>
            <CssBaseline />
            <BrowserRouter>
                <Suspense>
                    <Routes>
                        {AllRoutes?.map(routeObj => (
                            <Route exact path={routeObj.url} key={routeObj.url || routeObj.page} element={!routeObj.layout ? 
                                <routeObj.page />
                                :
                                <routeObj.layout menuActiveKey={routeObj["activeKey"]} isDark={themeMode === "dark"} setThemeMode={onModeChange}><routeObj.page menuActiveKey={routeObj["activeKey"]}/>
                            </routeObj.layout>} />
                        ))}
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
