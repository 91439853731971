const PALETTE_LIGHT = {
    background: {
        default: "#F5F2E8",
    },
    text: {
        dark: "#070910",
        main: "#0A0D17",
        light: "#3b3d45",
        primary: "#0A0D17",
    },
};

export default PALETTE_LIGHT;