import { useState } from "react";
import { Container, useTheme } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import AmbienceSound from "../components/AmbienceSound";

const NotFoundLayout = ({children, setThemeMode, isDark}) => {
    const theme = useTheme();
    const [ambienceVol, setAmbienceVol] = useState(0);
    return (
        <main className="min-h-screen flex flex-col">
            <NavBar isDark={isDark} onModeChange={setThemeMode} ambienceVol={ambienceVol} setAmbienceVol={setAmbienceVol} />
            <AmbienceSound vol={ambienceVol} setAmbienceVol={setAmbienceVol} />
            <Container maxWidth="lg">
                <div className="w-full h-1 sm:h-1.5 mb-1" style={{ backgroundColor: theme.palette.text.primary }} />
            </Container>
            {children}
            <Footer isStick={true} />
        </main>
    );
}

export default NotFoundLayout;