import React, { useEffect, useRef } from "react";

import MyAmbienceSound from "../assets/files/72_tr1.mp3";

const AmbienceSound = ({vol, setAmbienceVol}) => {
    const ambienceRef = useRef();
    
    useEffect(() => {
        const myAudio = ambienceRef.current;
        window.addEventListener("click", () => {
            if(myAudio?.paused && !myAudio?.currentTime){
                setAmbienceVol(20);
                myAudio.volume = 0.2;
                myAudio.play();
            }
        })
    }, [])

    useEffect(() => {
        const myAudio = ambienceRef.current;
        if(!myAudio?.paused && myAudio?.currentTime){
            myAudio.volume = vol / 100;
            if((vol === 0) || !vol){
                myAudio.muted = true;
            }else{
                myAudio.muted = false;
            }
        }else if(vol > 0){
            myAudio.muted = false;
            myAudio.volume = vol / 100;
            myAudio.play();
        }
    }, [vol])
    
    return (
        <audio src={MyAmbienceSound} ref={ambienceRef} id="musicplayer" preload="metadata" autoPlay allow="autoplay" onPlay={(e)=>vol ? e.target.volume = vol/100 : setAmbienceVol(e.target.volume)} loop></audio>
    );
};

export default AmbienceSound;