import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <div className="flex-grow flex flex-col items-center justify-center px-2 text-center">
            <div className="text-4xl font-bold">404</div>
            <div className="text-md sm:text-lg">Sorry, this page doesn't seem to exist. Try checking the URL or go back to the homepage.</div>
            <div className="pt-4">
                <Button onClick={() => navigate('/')}>Return Home</Button>
            </div>
        </div>
    );
}

export default NotFoundPage;