import React from "react";

const Footer = ({ isStick = false }) => {
    return (
        <footer className={isStick ? "w-full py-4 fixed bottom-0" : "w-full py-4"}>
            <div className="flex justify-center items-center">
                <div className="mt-0.5 pr-1">
                    <span>&copy;</span>
                </div>
                <small>{new Date().getFullYear()} PePatcha. All Rights Reserved.</small>
            </div>
        </footer>
    );
};

export default Footer;