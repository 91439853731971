import HomeLayout from "../layouts/HomeLayout";
import NotFoundLayout from "../layouts/NotFoundLayout";

import HomePage from "../pages/HomePage";
import NotFoundPage from "../pages/NotFoundPage";

const components = {
    home: {
        key: "home",
        isFirstPage: true,
        activeKey: "home",
        url: "/",
        page: HomePage,
        layout: HomeLayout,
    },
    notFound: {
        key: "pageNotFound",
        activeKey: "pageNotFound",
        url: "*",
        page: NotFoundPage,
        layout: NotFoundLayout
    }
};

const {
    home,
    notFound,
} = components;

const router = [home, notFound];
export default router;

let defaultUrl = "/";
for(const key in components) {
    const pageObj = components[key];
    if(pageObj.hasOwnProperty("isFirstPage") && pageObj["isFirstPage"]) {
        defaultUrl = pageObj["url"];
        break;
    }
}
export const firstPageUrl = defaultUrl;