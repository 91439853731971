const PALETTE_DARK = {
    background: {
        default: "#182030",
    },
    text: {
        dark: "#9e9da1",
        main: "#e2e1e6",
        light: "#e7e7eb",
        primary: "#e2e1e6",
    },
};

export default PALETTE_DARK;